import Swiper from "swiper";
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcElementId: 21,
            //             phoneElementId: 34,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof Wheeled Inspection Robot High-performance Edition',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Ex d IIC T6 Gb',
            //                     sort: 2
            //                 }, {
            //                     descName: 'IP67',
            //                     sort: 3
            //                 }, {
            //                     descName: '≥6h',
            //                     sort: 4
            //                 }, {
            //                     descName: '≤340kg',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: 'Explosion-proof Grade',
            //                     sort: 6
            //                 }, {
            //                     descName: 'Protection Level',
            //                     sort: 7
            //                 }, {
            //                     descName: 'Endurance Time',
            //                     sort: 8
            //                 }, {
            //                     descName: 'Curb Weight',
            //                     sort: 9
            //                 }],
            //             type: 'vedio',
            //             sort: 1
            //         },
            //         {
            //             pcElementId: 22,
            //             phoneElementId: 35,
            //             descList: [
            //                 {
            //                     descName: 'Performance Chassis',
            //                     sort: 1
            //                 }, {
            //                     descName: 'The overall structure adopts modular design and assembly to achieve dynamic adjustment of the body control, making the robot has the ultimate stability, support and passability. Through high-precision suspension, high-frequency shock absorption function is achieved, which not only ensures the smoothness of the inspection process and the accuracy of data, but also more stably supports the explosion-proof compartment, further improving inspection performance.',
            //                     sort: 2
            //                 },],
            //              type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcElementId: 23,
            //             phoneElementId: 36,
            //             descList: [
            //                 {
            //                     descName: 'Optimization',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Reduction',
            //                     sort: 2
            //                 }, {
            //                     descName: 'Reduction',
            //                     sort: 3
            //                 }, {
            //                     descName: 'Adaptation',
            //                     sort: 4
            //                 }, {
            //                     descName: 'Pitch & Roll',
            //                     sort: 5
            //                 }, {
            //                     descName: 'High Frequency Vibration',
            //                     sort: 6
            //                 }, {
            //                     descName: 'Slope Distance',
            //                     sort: 7
            //                 }, {
            //                     descName: '铺装及非铺装路面',
            //                     sort: 8
            //                 }, {
            //                     descName: 'High precision suspension, as stable as Mount Tai',
            //                     sort: 9
            //                 }, {
            //                     descName: 'High precision suspension, high-frequency shock absorption, ultra parking, able to walk freely in environments such as sidewalks, slopes, bumpy roads and slopes.',
            //                     sort: 10
            //                 },],
            //              type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcElementId: 24,
            //             phoneElementId: 37,
            //             descList: [
            //                 {
            //                     descName: 'Edge Module',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Millisecond Level',
            //                     sort: 2
            //                 }, {
            //                     descName: 'Powerful Computing Power Upgrade',
            //                     sort: 3
            //                 }, {
            //                     descName: 'Recognition Speed',
            //                     sort: 4
            //                 }, {
            //                     descName: 'Eagle eye identification, precise and flawless',
            //                     sort: 5
            //                 }, {
            //                     descName: 'Upgrading computing power, intelligent evolution of image identification algorithms, and improvement in millisecond level identification speed.',
            //                     sort: 6
            //                 },],
            //              type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcElementId:25,
            //             phoneElementId: 38,
            //             descList: [
            //                 {
            //                     descName: 'Less Than',
            //                     sort: 1
            //                 }, {
            //                     descName: '30+',
            //                     sort: 2
            //                 }, {
            //                     descName: 'Centimeter Level',
            //                     sort: 3
            //                 }, {
            //                     descName: 'Detection Distance Attenuation',
            //                     sort: 4
            //                 }, {
            //                     descName: 'Scenarios Expansion',
            //                     sort: 5
            //                 }, {
            //                     descName: 'Precise Positioning',
            //                     sort: 6
            //                 }, {
            //                     descName: '3D positioning, hole being illuminated like candle inside',
            //                     sort: 7
            //                 }, {
            //                     descName: 'RTK positioning system scenario optimization, unlocking special scenarios, centimeter level precise locking, inspection coverage without blind spots.',
            //                     sort: 8
            //                 },],
            //              type: 'image',
            //             sort: 5
            //         },
            //         {
            //             pcElementId: 26,
            //             phoneElementId: 39,
            //             descList: [
            //                 {
            //                     descName: 'Lightweight Design',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Smaller size with stronger spatial adaptability, being able to operate freely in narrow spaces',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 6
            //         },
            //         {
            //             pcElementId: 27,
            //             phoneElementId: 40,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof Heat Dissipation System',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Solve the problem of high temperature inside the product',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 7
            //         },
            //         {
            //             pcElementId: 28,
            //             phoneElementId: 41,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof Lidar',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Lidar detection distance attenuation less than 20%',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 8
            //         },
            //         {
            //             pcElementId:29,
            //             phoneElementId: 42,
            //             descList: [
            //                 {
            //                     descName: 'Ultrasonic omnidirectional anti-collision system',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Increase product self-protection and protection against surrounding objects, with adjustable detection distance',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 9
            //         },
            //         {
            //             pcElementId: 30,
            //             phoneElementId: 43,
            //             descList: [
            //                 {
            //                     descName: '3rd Generation VCU',
            //                     sort: 1
            //                 }, {
            //                     descName: 'More types of hardware interfaces can meet the access needs of more types of sensors, improve communication anti-interference ability, and ensure communication quality',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 10
            //         },
            //         {
            //             pcElementId:31,
            //             phoneElementId:44,
            //             descList: [
            //                 {
            //                     descName: 'Programmable Power Supply System',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Increase monitoring of various power supply lines (non-drive lines) to flexibly combine and meet remote control needs',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 11
            //         },
            //         {
            //             pcElementId: 32,
            //             phoneElementId: 45,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof Wheeled Inspection Robot High-performance Edition',
            //                     sort: 0
            //                 },
            //                 {
            //                     descName: 'Specification',
            //                     sort: 1
            //                 },
            //                 {
            //                     descName: 'Dimension (L×W×H)',
            //                     sort: 2
            //                 },
            //                 {
            //                     descName: 'Explosion-proof Grade',
            //                     sort: 3
            //                 },
            //                 {
            //                     descName: 'Walking Speed',
            //                     sort: 4
            //                 },
            //                 {
            //                     descName: 'Obstacle Surmounting Ability',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: 'Charging Time ',
            //                     sort: 6
            //                 },
            //                 {
            //                     descName: 'Main Navigation Mode',
            //                     sort: 7
            //                 },
            //                 {
            //                     descName: 'Curb Weight',
            //                     sort: 8
            //                 },
            //                 {
            //                     descName: 'Protection Level',
            //                     sort: 9
            //                 }, {
            //                     descName: 'Gradeability',
            //                     sort: 10
            //                 },
            //                 {
            //                     descName: 'Wading Depth',
            //                     sort: 11
            //                 },
            //                 {
            //                     descName: 'Battery Endurance',
            //                     sort: 12
            //                 },
            //                 {
            //                     descName: 'Repetitive Navigation Positioning Accuracy',
            //                     sort: 13
            //                 }, {
            //                     descName: '1050mm×860mm×960mm',
            //                     sort: 14
            //                 },
            //                 {
            //                     descName: 'Ex d IIC T6 Gb',
            //                     sort: 15
            //                 },
            //                 {
            //                     descName: '0-1.5m/s，Speed Adjustable',
            //                     sort: 16
            //                 },
            //                 {
            //                     descName: '150mm',
            //                     sort: 17
            //                 },
            //                 {
            //                     descName: '2h to 80% (Fast Charging)',
            //                     sort: 18
            //                 },
            //                 {
            //                     descName: 'SLAM Laser Navigation and SLAM Laser Positioning',
            //                     sort: 19
            //                 },
            //                 {
            //                     descName: '≤340kg',
            //                     sort: 20
            //                 },
            //                 {
            //                     descName: 'IP67',
            //                     sort: 21
            //                 }, {
            //                     descName: '≤25°',
            //                     sort: 22
            //                 },
            //                 {
            //                     descName: '200mm',
            //                     sort: 23
            //                 },
            //                 {
            //                     descName: '≥6h at Speed 1m/S, ≥24h when Standby',
            //                     sort: 24
            //                 },
            //                 {
            //                     descName: '≤±10mm',
            //                     sort: 25
            //                 }],
            //              type: 'image',
            //             sort: 12
            //         },
            //         {
            //             pcElementId:33,
            //             phoneElementId: 46,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof Wheeled Inspection Robot High-performance Edition',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Safe and high-performance, a new benchmark for inspection',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 13
            //         }
            //     ],
            elmentList:[],
            aboutswiper: null,
        }
    },
    mounted() {
        // this.$store.dispatch("pageset/getbannerIndex", 0);
        //         this.$store.dispatch("pageset/getSourceData",
        //     {
        //         createBy: '',
        //         updateBy: '',
        //         elmentList: this.elmentList,
        //         pageId: this.$route.query.pageId,//页面id
        //         pageName: this.$route.path,
        //         path: this.$route.path,
        //         remark: '',
        //     }
        // );
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
            setTimeout(() => {
                this.aboutSwiper();
                // 取消这个方式加载视频
                if (!this.$store.state.pageset.pcshow) {
                    let video = document.getElementById('videoOne');
                    video.play();
                  }
            }, 100);
        });
    },
    // 销毁滚轮事件
    destroyed() {
        // 销毁swiper
        this.aboutswiper.destroy();
    },
    methods: {
        retention() {
            this.$router.push('/en/reservation');
        },
        // 初始化轮播图
        aboutSwiper() {
            let that = this;
            this.aboutswiper = new Swiper('.mySwiper', {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '">' +
                            '<p class="p-name">' + (that.elmentList[index + 5].descList[0].descName) +
                            '</p>' +
                            '<p class="p-dec">' + (that.elmentList[index + 5].descList[1].descName) +
                            '</p>'
                            + '</span>';
                    },
                },
                autoplay: true,
                speed: 2000,
                loop: true,
                effect: 'fade',//切换效果
                fadeEffect: {
                    crossFade: true  //开启淡出。过渡时，原slide透明度从1->0（淡出），过渡中的slide透明度从0->1（淡入），其他slide透明度0。
                    //false  关闭淡出。过渡时，原slide透明度为1（不淡出），过渡中的slide透明度从0->1（淡入），其他slide透明度0。
                },
            });
        },
        openPdf() {
             this.$router.push('/pdfView')
        },
    }
}